export const endpoint = {
    login: 'api/payment-platform-bo/auth/v1/login/google',
    logout: 'api/payment-platform-bo/auth/v1/logout',
    mainTransactionInquiry: 'api/payment-platform-bo/transaction-inquiry/v1/payment-main-txn',
    bppInquiry: 'api/payment-platform-bo/transaction-inquiry/v1/bpp',
    lovConfig: 'api/payment-platform-bo/transaction-inquiry/v1/lov-config',
    coPayMainInquiry: 'api/payment-platform-bo/transaction-inquiry/v1/copay/main-txn',
    coPaySubInquiry: 'api/payment-platform-bo/transaction-inquiry/v1/copay/sub-txn',

    /* user access mgmt */
    userInquiry: 'api/payment-platform-bo/user/v1/search',
    userInsert: 'api/payment-platform-bo/user/v1/insert',
    userUpdate: 'api/payment-platform-bo/user/v1/update',
    userDelete: 'api/payment-platform-bo/user/v1/delete',
    userLov: 'api/payment-platform-bo/user/v1/query',

    /* biller profile mgmt */
    billerProfileMgmtSearch: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-profile-management/search',
    billerProfileMgmtEdit: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-profile-management/edit',
    billerProfileMgmtEditByPayeeCode: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-profile-management/edit-by-payeecode',

    /* biller configuration */
    maintainIconUrlSearch: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-icon-url/search',
    maintainIconUrlAdd: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-icon-url/add',
    maintainIconUrlEdit: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-icon-url/edit',

    /* maintain directlink configuration */
    maintainDirectlinkSearch: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-directlink-configuration/search',
    maintainDirectlinkAdd: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-directlink-configuration/add',
    maintainDirectlinkEdit: 'api/payment-platform-bo/webadmin-biller-profile/v1/maintain-directlink-configuration/edit',

    /* biller maintenance configuration */
    billerMaintainSearch: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-maintain-configuration/search',
    billerMaintainAdd: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-maintain-configuration/add',
    billerMaintainEdit: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-maintain-configuration/edit',
    billerMaintainDelete: 'api/payment-platform-bo/webadmin-biller-profile/v1/biller-maintain-configuration/delete',

    /* biller fee configuration */
    billerFeeSearch: 'api/payment-platform-bo/webadmin-biller-profile/v1/fee-configuration-inquiry/search',

    /* maintain partner payment */
    partnerConfigurationSearch: '/api/payment-platform-bo/webadmin-biller-profile/v1/xxx/search',
    partnerConfigurationAdd: '/api/payment-platform-bo/webadmin-biller-profile/v1/xxx/add',
    partnerConfigurationEdit: '/api/payment-platform-bo/webadmin-biller-profile/v1/xxx/edit',

}