import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AuthAccessStore {
  private subject = new BehaviorSubject<number[]>([])
  observer = this.subject.asObservable()

  set(value: number[]): void {
    this.subject.next(value)
  }

  get(): number[] {
    return this.subject.getValue()
  }

  clear(): void {
    this.subject.next([])
  }
}