import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { routePath } from "../constants/route-path";
import { GoogleAuthService } from "../pages/google-auth/services/google-auth-service";

export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
    const authService = inject(GoogleAuthService)
    const router = inject(Router)

    return authService.isAuthenticated() ? true : router.navigate([`/${routePath.login}`])
}