import { Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog';
import { DisplayInfo } from '../../models/display-info';
import { DisplayInfoService } from '../../services/display-info.service';

@Component({
  selector: 'app-warn-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
  ],
  templateUrl: './warn-dialog.component.html',
  styleUrl: './warn-dialog.component.scss'
})
export class WarnDialogComponent {
  service = inject(DisplayInfoService)

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DisplayInfo,
  ) { }
}
