import { Component, HostListener, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { endpoint } from './constants/endpoint';
import { storageKey } from './constants/storage-key';
import { AuthRes } from './models/auth-res';
import { SignOutReq } from './models/sign-out-req';
import { GoogleAuthService } from './pages/google-auth/services/google-auth-service';
import { matDateAdapterProvider } from './providers/mat-date-adapter.provider';
import { matDateFormatsProvider } from './providers/mat-date-formats.provider';
import { matFormFieldSizingProvider } from './providers/mat-form-field-sizing.provider';
import { LocalStorageService } from './services/local-storage.service';
import { SessionStorageService } from './services/session-storage.service';
import { TabStackService } from './services/tab-stack.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [
    matFormFieldSizingProvider,
    matDateAdapterProvider,
    matDateFormatsProvider,
  ]
})
export class AppComponent {
  private tabStackService = inject(TabStackService)
  private authService = inject(GoogleAuthService)
  private localStorageService = inject(LocalStorageService)
  private sessionStorageService = inject(SessionStorageService)

  constructor() {
    this.tabStackService.add()
    this.refreshManyPages()
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeCloseTabOrBrowserAndRefresh(event: Event): void {
    this.tabStackService.remove()
    if (this.tabStackService.isEmpty()) {
      this.refreshOnePage()
      this.localStorageService.delete(storageKey.auth)
    }
  }

  private refreshOnePage(): void {
    const auth: AuthRes | null = this.localStorageService.get(storageKey.auth)
    if (auth) this.sessionStorageService.set(storageKey.auth, auth)
  }

  private refreshManyPages(): void {
    const auth: AuthRes | null = this.sessionStorageService.get(storageKey.auth)
    if (auth) this.localStorageService.set(storageKey.auth, auth)
  }

  private signOut() {
    const authRes: AuthRes | null = this.authService.getAuthenticated()
    if (authRes) {
      const payload: SignOutReq = {
        userRefId: authRes.userRefId,
      }
      const url = `${environment.domain}/${endpoint.logout}`
      // force sign out
      // https://stackoverflow.com/questions/40523469/navigator-sendbeacon-to-pass-header-information
      fetch(url, {
        keepalive: true,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': authRes.authorization,
          'X-Correlation-ID': `${crypto.randomUUID()}-crid`,
          'X-Request-ID': crypto.randomUUID(),
        },
        body: JSON.stringify(payload),
      })
    }
  }
}