import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    MatButtonModule,
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  @Input() ref: LoaderService | undefined = undefined

  onCancelUnsubscribe(): void {
    if (this.ref) {
      this.ref.getCancelableSubscribe().unsubscribe()
      this.ref.hide()
    }
  }
}
