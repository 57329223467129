import { Injectable } from "@angular/core";
import { AES, MD5 } from "crypto-js";
import { environment } from "../../environments/environment";
import * as CryptoJS from 'crypto-js';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  get<T>(key: string): T | null {
    const k = environment.production ? MD5(key).toString() : key
    const v = sessionStorage.getItem(k)

    if (!v) return null
    
    const value = environment.production ? AES.decrypt(v, environment.encryptedSalt).toString(CryptoJS.enc.Utf8) : v
    
    return value ? JSON.parse(value) as T : null
  }

  set<T>(key: string, value: T): void {
    const k = environment.production ? MD5(key).toString() : key
    const v = environment.production ? AES.encrypt(JSON.stringify(value), environment.encryptedSalt).toString() : JSON.stringify(value)
    sessionStorage.setItem(k, v)
  }

  delete(key: string): void {
    const k = environment.production ? MD5(key).toString() : key
    sessionStorage.removeItem(k)
  }
}