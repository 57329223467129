import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from "@angular/common/http";
import { inject } from "@angular/core";
import { tap } from "rxjs";
import { LoaderService } from "../services/loader.service";

export const loaderInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const loaderService = inject(LoaderService)

  if (!isGoogleAuthUrl(req.url)) {
    loaderService.show()
  }

  return next(req).pipe(
    tap({
      next: (event: HttpEvent<unknown>) => { if (event instanceof HttpResponse) loaderService.hide() },
      error: (err: HttpErrorResponse) => loaderService.hide(),
    }),
  )
}

const isGoogleAuthUrl = (url: string): boolean => {
  return new RegExp('/login/google').test(url)
}