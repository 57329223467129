import { AuthAccess } from "../models/auth-access";

export const permission = {
  viewUam: 'view-uam',
  viewBillPayment: 'view-bill-payment',
  viewCoPay: 'view-co-pay',
  viewSubCoPay: 'view-sub-co-pay',
  viewMainTransaction: 'view-main-transaction',
  addUam: 'add-uam',
  updateUam: 'update-uam',
  deleteUam: 'delete-uam',
  inquiryUam: 'inquiry-uam',
  inquiryBillPayment: 'inquiry-bill-payment',
  inquiryCoPay: 'inquiry-co-pay',
  inquirySubCoPay: 'inquiry-sub-co-pay',
  inquiryMainTransaction: 'inquiry-main-transaction',
  exportUam: 'export-uam',
  exportBillPayment: 'export-bill-payment',
  exportCoPay: 'export-co-pay',
  exportSubCoPay: 'export-sub-co-pay',
  exportMainTransaction: 'export-main-transaction',

  /* biller profile mgmt */
  viewBillerProfileMgmt: 'view-biller-profile-mgmt',
  searchBillerProfileMgmt: 'search-biller-profile-mgmt',
  editBillerProfileMgmt: 'edit-biller-profile-mgmt',
  editByPayeeCodeBillerProfileMgmt: 'edit-by-payee-code-biller-profile-mgmt',

  /* biller configuration */
  viewMaintainIconUrl: 'view-maintain-icon-url',
  searchMaintainIconUrl: 'search-maintain-icon-url',
  addMaintainIconUrl: 'add-maintain-icon-url',
  editMaintainIconUrl: 'edit-maintain-icon-url',

  /* maintain directlink configuration */
  viewMaintainDirectlink: 'view-maintain-directlink',
  searchMaintainDirectlink: 'search-maintain-directlink',
  addMaintainDirectlink: 'add-maintain-directlink',
  editMaintainDirectlink: 'edit-maintain-directlink',

  /* biller maintenance configuration */
  viewBillerMaintain: 'view-biller-maintain',
  searchBillerMaintain: 'search-biller-maintain',
  addBillerMaintain: 'add-biller-maintain',
  editBillerMaintain: 'edit-biller-maintain',
  deleteBillerMaintain: 'delete-biller-maintain',

  /* biller fee configuration */
  viewBillerFee: 'view-biller-fee',
  searchBillerFee: 'search-biller-fee',

  /* maintain partner payment */
  viewPartnerConfiguration: 'view-partner-configuration',
  searchPartnerConfiguration: 'search-partner-configuration',
  addPartnerConfiguration: 'add-partner-configuration',
  editPartnerConfiguration: 'edit-partner-configuration',
}

/* https://ktbinnovation.atlassian.net/wiki/spaces/PMT/pages/3299442702/Table+-+roles+config */
export const authAccesses: AuthAccess[] = [
  {
    no: 1, name: 'user_mgnt_access', permissions: [
      permission.viewUam,
      permission.inquiryUam,
    ]
  },
  {
    no: 2, name: 'modify_user', permissions: [
      permission.addUam,
      permission.updateUam,
      permission.deleteUam,
    ]
  },
  {
    no: 3, name: 'bpp_txn_log_access', permissions: [
      permission.viewBillPayment,
      permission.inquiryBillPayment,
    ]
  },
  {
    no: 4, name: 'copay_main_txn_log_access', permissions: [
      permission.viewCoPay,
      permission.inquiryCoPay,
    ]
  },
  {
    no: 5, name: 'copay_sub_txn_log_access', permissions: [
      permission.viewSubCoPay,
      permission.inquirySubCoPay,
    ]
  },
  {
    no: 6, name: 'bpp_export', permissions: [
      permission.viewBillPayment,
      permission.exportBillPayment,
    ]
  },
  {
    no: 7, name: 'copay_main_export', permissions: [
      permission.viewCoPay,
      permission.exportCoPay,
    ]
  },
  {
    no: 8, name: 'copay_sub_export', permissions: [
      permission.viewSubCoPay,
      permission.exportSubCoPay,
    ]
  },
  {
    no: 9, name: 'pp_main_txn_log_access', permissions: [
      permission.viewMainTransaction,
      permission.inquiryMainTransaction,
    ]
  },
  {
    no: 10, name: 'pp_main_txn_export_access', permissions: [
      permission.viewMainTransaction,
      permission.exportMainTransaction,
    ]
  },
  {
    no: 11, name: 'user_export', permissions: [
      permission.viewUam,
      permission.exportUam,
    ]
  },
  {
    no: 12, name: 'biller_profile_log_access', permissions: [
      permission.viewBillerProfileMgmt,
      permission.searchBillerProfileMgmt,

      permission.viewBillerFee,
      permission.searchBillerFee,

      permission.viewMaintainIconUrl,
      permission.searchMaintainIconUrl,

      permission.viewMaintainDirectlink,
      permission.searchMaintainDirectlink,

      permission.viewBillerMaintain,
      permission.searchBillerMaintain,
    ]
  },
  {
    no: 13, name: 'biller_profile_modify_access', permissions: [
      permission.viewBillerProfileMgmt,
      permission.editBillerProfileMgmt,
      permission.editByPayeeCodeBillerProfileMgmt,

      permission.viewMaintainIconUrl,
      permission.addMaintainIconUrl,
      permission.editMaintainIconUrl,

      permission.viewMaintainDirectlink,
      permission.addMaintainDirectlink,
      permission.editMaintainDirectlink,

      permission.viewBillerMaintain,
      permission.addBillerMaintain,
      permission.editBillerMaintain,
      permission.deleteBillerMaintain,
    ]
  },
  {
    no: 14, name: 'partner_config_log_access', permissions: [
      permission.viewPartnerConfiguration,
      permission.searchPartnerConfiguration,
    ]
  },
  {
    no: 15, name: 'partner_config_modify_access', permissions: [
      permission.viewPartnerConfiguration,
      permission.addPartnerConfiguration,
      permission.editPartnerConfiguration,
    ]
  },
]