import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: 'root' })
export class BaseService {
    constructor(
        private http: HttpClient,
    ) { }

    get<T>(uri: string, headers: HttpHeaders): Observable<T> {
        const url = `${environment.domain}/${uri}`

        return this.http.get<T>(url, { headers })
    }

    post<T, R>(uri: string, payload: T, headers: HttpHeaders): Observable<R> {
        const url = `${environment.domain}/${uri}`

        return this.http.post<R>(url, payload, { headers })
    }

    postForFullResponse<T, R>(uri: string, payload: T, headers: HttpHeaders): Observable<HttpResponse<R>> {
        const url = `${environment.domain}/${uri}`
        return this.http.post<R>(url, payload, { headers, observe: 'response' })
    }

    put<T, R>(uri: string, payload: T, headers: HttpHeaders): Observable<R> {
        const url = `${environment.domain}/${uri}`

        return this.http.put<R>(url, payload, { headers })
    }

    delete<R>(uri: string, headers: HttpHeaders): Observable<R> {
        const url = `${environment.domain}/${uri}`

        return this.http.delete<R>(url, { headers })
    }
}
