import { Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { ComponentRef, Injectable, inject } from "@angular/core";
import { Subscription } from "rxjs";
import { LoaderComponent } from "../components/loader/loader.component";

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private componentRef: ComponentRef<LoaderComponent> | undefined = undefined
  private overlay = inject(Overlay)
  private subscription = Subscription.EMPTY

  show(): void {
    if (!this.componentRef) {
      this.componentRef = this.overlay.create({
        positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
        hasBackdrop: true
      }).attach(new ComponentPortal(LoaderComponent))
    }
  }

  hide(): void {
    if (this.componentRef) {
      this.componentRef.destroy()
      this.componentRef = undefined
    }
  }

  setCancelableSubscribe(subscription: Subscription): void {
    this.subscription = subscription
    if (this.componentRef) {
      this.componentRef.setInput('ref', this)
    }
  }

  getCancelableSubscribe(): Subscription {
    return this.subscription
  }
}