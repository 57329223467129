import { HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map } from "rxjs";
import { RefreshStorageService } from "src/app/services/refresh-storage.service";
import { environment } from "../../../../environments/environment";
import { endpoint } from "../../../constants/endpoint";
import { storageKey } from "../../../constants/storage-key";
import { AuthRes } from "../../../models/auth-res";
import { SignInReq } from "../../../models/sign-in-req";
import { SignOutReq } from "../../../models/sign-out-req";
import { AuthAccessStore } from "../../../services/auth-access.store";
import { BaseService } from "../../../services/base.service";
import { LocalStorageService } from "../../../services/local-storage.service";

@Injectable({ providedIn: 'root' })
export class GoogleAuthService {
    private baseService = inject(BaseService)
    private localStorageService = inject(LocalStorageService)
    private refreshStorageServices = inject(RefreshStorageService)
    private authAccessStore = inject(AuthAccessStore)

    getAuthenticated(): AuthRes | null {
        return this.refreshStorageServices.get<AuthRes>(storageKey.auth)
    }

    isAuthenticated(): boolean {
        return !!this.getAuthenticated()
    }

    signIn(googleIdToken: string, removePreviousSession = false): Observable<AuthRes | null> {
        const payload: SignInReq = {
            googleIdToken,
            removePreviousSession,
        }
        const uri = `${endpoint.login}`

        return this.baseService.postForFullResponse<SignInReq, AuthRes>(uri, payload, new HttpHeaders({
            'X-Client-Version': environment.version,
            'X-Channel-ID': 'WEB',
            'X-Correlation-ID': `${crypto.randomUUID()}-crid`,
            'X-Request-ID': crypto.randomUUID(),
        })).pipe(
            catchError((err: HttpErrorResponse) => {
                this.refreshStorageServices.delete(storageKey.auth)
                throw err
            }),
            map((res: HttpResponse<AuthRes>) => {
                const authorization = res.headers.get('Authorization')
                if (res.body && authorization) {
                    this.localStorageService.set<AuthRes>(storageKey.auth, {
                        ...res.body,
                        authorization,
                    })
                    /* map properties into store(s) 
                    if we want to use instead of call to storageService.get directly */
                    this.authAccessStore.set(res.body.access)
                }

                return res.body
            })
        )
    }

    signOut(userRefId: string, inactiveReason = ''): Observable<void> {
        const payload: SignOutReq = {
            userRefId,
            inactiveReason
        }
        const uri = `${endpoint.logout}`

        return this.baseService.post<SignOutReq, void>(uri, payload, this.getHeader())
    }

    getHeader(): HttpHeaders {
        const authRes: AuthRes | null = this.getAuthenticated()
        const authorization = authRes ? authRes.authorization : ''
        return new HttpHeaders({
            authorization,
            'X-Correlation-ID': `${crypto.randomUUID()}-crid`,
            'X-Request-ID': crypto.randomUUID(),
        })
    }
}