import { MAT_DATE_FORMATS } from "@angular/material/core";

export const matDateFormatsProvider = {
  provide: MAT_DATE_FORMATS, useValue: {
    parse: { dateInput: 'LL' },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
    }
  }
}