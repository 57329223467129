import { Injectable } from "@angular/core";
import { storageKey } from "../constants/storage-key";

interface TabStack {
  id: string
}

@Injectable({ providedIn: 'root' })
export class TabStackService {
  private tabId = ''

  constructor() {
    this.tabId = crypto.randomUUID()
  }

  private get(): TabStack[] {
    const value: string | null = localStorage.getItem(storageKey.tabStack)
    return value ? JSON.parse(value) as TabStack[] : []
  }

  private set(stacks: TabStack[]): void {
    localStorage.setItem(storageKey.tabStack, JSON.stringify(stacks))
  }

  isEmpty(): boolean {
    return this.get().length === 0
  }

  add(): void {
    const stacks = this.get()
    stacks.push({ id: this.tabId })
    this.set(stacks)
  }

  remove(): void {
    this.set(this.get().filter(d => d.id !== this.tabId))
  }

  clear(): void {
    localStorage.removeItem(storageKey.tabStack)
  }
}