import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { loaderInterceptor } from './interceptors/loader-interceptor';
import { httpResponseInterceptor } from './interceptors/http-response-interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        loaderInterceptor,
        httpResponseInterceptor,
      ])
    )
  ]
};
