import { Injectable, inject } from "@angular/core";
import { authAccesses } from "../constants/auth-accesses";
import { storageKey } from "../constants/storage-key";
import { AuthAccess } from "../models/auth-access";
import { AuthRes } from "../models/auth-res";
import { AuthAccessStore } from "./auth-access.store";
import { RefreshStorageService } from "./refresh-storage.service";

@Injectable({ providedIn: 'root' })
export class AuthAccessService {
  private authAccessStore = inject(AuthAccessStore)
  private refreshStorageServices = inject(RefreshStorageService)

  get(): AuthAccess[] {
    // check after reload page
    if (this.authAccessStore.get().length === 0) {
      const authRes: AuthRes | null = this.refreshStorageServices.get<AuthRes>(storageKey.auth)
      this.authAccessStore.set(authRes ? authRes.access : [])
    }

    const accesses = this.authAccessStore.get()
    // const accesses = [1, 2, 11] /* User Admin */
    // const accesses = [1, 3, 4, 5, 6, 7, 8, 9, 10, 12] /* Digital Strategy Platform Supervisor */
    // const accesses = [3, 4, 5, 9, 12] /* Digital Strategy Platform Staff */
    // const accesses = [1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15] /* Digital Strategy Platform - Maintain Data */
    // const accesses = [3, 4, 5, 6, 7, 8, 9, 10, 12] /* IT Payment Platform Supervisor */
    // const accesses = [3, 4, 5, 9, 12] /* IT Payment Platform Staff */
    // const accesses = [1, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15] /* IT Payment Platform - Maintain Data */
    // const accesses = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15] /* dev */

    const list: AuthAccess[] = []
    accesses.forEach(no => {
      const a = authAccesses.find(a => a.no === no)
      if (a) list.push(a)
    })

    return list
  }
}