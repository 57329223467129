import { Injectable } from "@angular/core";
import { DisplayInfo } from "../models/display-info";

@Injectable({ providedIn: 'root' })
export class DisplayInfoService {
  getTitle(info: DisplayInfo): string {
    return info.title || 'Attention'
  }

  getCode(info: DisplayInfo): string {
    return info.apiError ? info.apiError.code : ''
  }

  getDescription(info: DisplayInfo): string {
    if (info.description) return info.description
    return info.apiError ? info.apiError.description : ''
  }
}