import { Routes } from '@angular/router';
import { permission } from './constants/auth-accesses';
import { routePath } from './constants/route-path';
import { canActivate } from './guards/auth-guard';
import { canAccess } from './guards/permission-guard';

export const routes: Routes = [
  {
    path: routePath.login,
    loadComponent: () => import('./pages/google-auth/google-auth.component').then(c => c.GoogleAuthComponent),
    title: 'Login',
  },
  {
    path: routePath.landing,
    canActivate: [canActivate],
    children: [
      {
        path: routePath.landing,
        loadComponent: () => import('./pages/landing/landing.component').then(c => c.LandingComponent),
        title: 'Home',
        children: [
          {
            path: routePath.mainTransaction,
            loadComponent: () => import('./pages/main-transaction/main-transaction.component').then(c => c.MainTransactionComponent),
            title: 'Bill Payment - Main Transaction',
            canActivate: [canAccess],
            data: { permission: permission.viewMainTransaction },
          },
          {
            path: routePath.billPayment,
            loadComponent: () => import('./pages/bill-payment/bill-payment.component').then(c => c.BillPaymentComponent),
            title: 'Bill Payment',
            canActivate: [canAccess],
            data: { permission: permission.viewBillPayment },
          },
          {
            path: routePath.coPay,
            loadComponent: () => import('./pages/co-pay/co-pay.component').then(c => c.CoPayComponent),
            title: 'Co Pay',
            canActivate: [canAccess],
            data: { permission: permission.viewCoPay },
          },
          {
            path: routePath.subCoPay,
            loadComponent: () => import('./pages/sub-co-pay/sub-co-pay.component').then(c => c.SubCoPayComponent),
            title: 'Co Pay',
            canActivate: [canAccess],
            data: { permission: permission.viewSubCoPay },
          },
          {
            path: routePath.uam,
            loadComponent: () => import('./pages/user-access-mgmt/user-access-mgmt.component').then(c => c.UserAccessMgmtComponent),
            title: 'User Access Management',
            canActivate: [canAccess],
            data: { permission: permission.viewUam },
          },
          {
            path: routePath.billerProfileMgmt,
            loadComponent: () => import('./pages/biller-profile-mgmt/biller-profile-mgmt.component').then(c => c.BillerProfileMgmtComponent),
            title: 'Biller Profile Management',
            canActivate: [canAccess],
            data: { permission: permission.viewBillerProfileMgmt },
          },
          {
            path: routePath.billerMaintain,
            loadComponent: () => import('./pages/biller-maintenance-configuration/biller-maintenance-configuration.component').then(c => c.BillerMaintenanceConfigurationComponent),
            title: 'Biller Maintenance Configuration',
            canActivate: [canAccess],
            data: { permission: permission.viewBillerMaintain },
          },
          {
            path: routePath.maintainIconUrl,
            loadComponent: () => import('./pages/maintain-icon-and-url/maintain-icon-and-url.component').then(c => c.MaintainIconAndUrlComponent),
            title: 'Maintain Icon And Url',
            canActivate: [canAccess],
            data: { permission: permission.viewMaintainIconUrl },
          },
          {
            path: routePath.billerFee,
            loadComponent: () => import('./pages/biller-fee-configuration/biller-fee-configuration.component').then(c => c.BillerFeeConfigurationComponent),
            title: 'Fee Configuration Inquiry',
            canActivate: [canAccess],
            data: { permission: permission.viewBillerFee },
          },
          {
            path: routePath.maintainDirectlink,
            loadComponent: () => import('./pages/maintain-directlink-configuration/maintain-directlink-configuration.component').then(c => c.MaintainDirectlinkConfigurationComponent),
            title: 'Maintain Directlink Configuration',
            canActivate: [canAccess],
            data: { permission: permission.viewMaintainDirectlink },
          },
          {
            path: routePath.partnerConfiguration,
            loadComponent: () => import('./pages/maintain-partner-payment/maintain-partner-payment.component').then(c => c.MaintainPartnerPaymentComponent),
            title: 'Maintain Partner Payment',
            canActivate: [canAccess],
            data: { permission: permission.viewPartnerConfiguration },
          },
        ]
      },
    ]
  },
  {
    path: routePath.any,
    redirectTo: '/',
    pathMatch: 'full',
  }
];
