import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { routePath } from "../constants/route-path";
import { AuthAccess } from "../models/auth-access";
import { AuthAccessService } from "../services/auth-access.service";

export const canAccess: CanActivateFn = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  const router = inject(Router)
  const accesses: AuthAccess[] = inject(AuthAccessService).get()
  const list = accesses.map(a => a.permissions).reduce((a, b) => a.concat(b), [])
  const permission = route.data['permission'] || ''

  return list.includes(permission) ? true : router.navigate([`/${routePath.login}`])
}