import { Injectable, inject } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageService } from "./session-storage.service";
import { TabStackService } from "./tab-stack.service";

@Injectable({ providedIn: 'root' })
export class RefreshStorageService {
  private localStorageService = inject(LocalStorageService)
  private sessionStorageService = inject(SessionStorageService)
  private tabStackService = inject(TabStackService)

  get<T>(key: string): T | null {
    const value: T | null = this.localStorageService.get<T>(key)
    if (!value) return this.sessionStorageService.get<T>(key)
    return value
  }

  delete(key: string): void {
    this.localStorageService.delete(key)
    this.sessionStorageService.delete(key)
    this.tabStackService.clear()
  }
}